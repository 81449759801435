.bcsaus-main__cover {
  background: url("/src/images/main-cover.webp") center / cover no-repeat;
  min-height: 548px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.bcsaus-main__header {
  max-width: 734px;
}

.bcsaus-about__container {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.bcsaus-about__text {
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bcsaus-why-us__img {
  background: url("/src/images/about-img.webp") right / contain no-repeat;
  width: 458px;
  height: 345px;
  flex-shrink: 0;
}

.bcsaus-history {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bcsaus-history__img {
  background: url("/src/images/history-img.webp") center / cover no-repeat;
  min-height: 504px;
}

.bcsaus-history__text-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 100px 50px 50px;
}

.bcsaus-features {
  background-color: #800077;
  min-height: 408px;
  display: flex;
  align-items: center;
  margin-top: 80px;
}

.bcsaus-features__body {
  padding-top: 0;
  padding-bottom: 0;
}

.bcsaus-features__list {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  list-style: disc;
  justify-content: center;
}

.bcsaus-features__item {
  max-width: 340px;
}

.bcsaus-perks__container {
  display: flex;
  gap: 40px;
  justify-content: space-between;
}

.bcsaus-perks__part {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 419px;
  width: 100%;
}

.bcsaus-perks__small-header {
  display: flex;
  gap: 20px;
  align-items: center;
}

.bcsaus-perks__list {
  list-style: disc;
  padding-left: 20px;
}

.bcsaus-faq__list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.bcsaus-faq__item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bcsaus-conclusion {
  background: url("/src/images/bottom-cover.webp") center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 513px;
  padding: 20px;
}

.bcsaus-conclusion__text {
  max-width: 717px;
  font-weight: 700;
}

@media (max-width: 1100px) {
  .bcsaus-why-us__img {
    width: 380px;
    height: 250px;
  }

  .bcsaus-history__text-container {
    gap: 25px;
    padding: 30px 60px 30px 30px;
  }

  .bcsaus-features__list {
    gap: 30px;
  }
}

@media (max-width: 749px) {
  .bcsaus-main__cover {
    background: url("/src/images/main-cover-mob.webp") center / cover no-repeat;
  }

  .bcsaus-conclusion {
    background: url("/src/images/bottom-cover-mob.webp") center / cover
      no-repeat;
      height: 768px;
  }

  .bcsaus-about__container {
    flex-direction: column;
  }

  .bcsaus-about__text {
    max-width: 100%;
  }

  .bcsaus-why-us__img {
    background: url("/src/images/about-img.webp") right / cover no-repeat;
    width: 100%;
    height: 312px;
  }

  .bcsaus-history {
    display: flex;
    flex-direction: column-reverse;
  }

  .bcsaus-history__img {
    min-height: 300px;
  }

  .bcsaus-history__text-container {
    padding: 30px 20px;
    gap: 15px;
  }

  .bcsaus-features__list {
    flex-direction: column;
  }

  .bcsaus-features__item {
    max-width: 100%;
  }

  .bcsaus-perks__container, .bcsaus-faq__list {
    flex-direction: column;
    gap: 30px;
  }

  .bcsaus-perks__part {
    gap: 20px;
    max-width: 100%;
  }
}
