.bcsaus-footer {
  gap: 10px;
  padding-bottom: 40px;
}

.bcsaus-footer__top {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.bcsaus-footer__text {
  max-width: 316px;
  width: 100%;
}

.bcsaus-footer__link {
  text-decoration: underline;
}

.bcsaus-footer__policy {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  flex-shrink: 0;
}

@media (max-width: 1100px) {
}

@media (max-width: 749px) {
  .bcsaus-footer__top {
    flex-direction: column;
  }

  .bcsaus-footer__text {
    max-width: 100%;
  }

  .bcsaus-footer__policy  {
    flex-direction: row;
    justify-content: space-between;
  }
}
