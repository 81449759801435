.bcsaus-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 9999;
  padding: 200px 25px 25px;
}

.bcsaus-popup-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 673px;
  min-height: 300px;
  z-index: 3;
  background: #000000;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.bcsaus-popup__body {
  width: 100%;
  max-width: 673px;
  min-height: 300px;
  position: relative;
  background: #000000;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.bcsaus-popup__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 573px;
}

.bcsaus-popup__text {
  font-size: 18px;
  line-height: 27px;
}

.bcsaus-popup__cookie-text {
  font-size: 13px;
  line-height: 19px;
}

.bcsaus-popup__btn {
  width: 100%;
  max-width: 244px;
  height: 48px;
  cursor: pointer;
  background: #ff00ee;
  border: none;
  text-align: center;
  border-radius: 18px;
  color: #ffffff;
}

.bcsaus-popup__btn-container {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 15px;
}

.bcsaus-popup__btn-cookie {
  width: 100%;
  height: 48px;
  cursor: pointer;
  border: 1px solid #ff00ee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 18px;
}

.bcsaus-popup__btn-agree {
  background: #ff00ee;
}

.disabled {
  display: none;
}

@media (max-width: 749px) {
  .bcsaus-popup__form .t-32 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 480px) {
  .bcsaus-popup__btn-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
