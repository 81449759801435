.bcsaus-game-page {
  margin: 40px auto;
  gap: 40px;
}

.bcsaus-game-page__frame {
  height: 600px;
}

.bcsaus-game-page__about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  row-gap: 50px;
}

.bcsaus-game-page__item {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 1100px) {
  .bcsaus-game-page {
    gap: 30px;
  }

  .bcsaus-game-page__frame {
    height: 400px;
  }

  .bcsaus-game-page__about {
    gap: 50px;
    row-gap: 30px;
  }

  .bcsaus-game-page__item {
    gap: 20px;
  }
}

@media (max-width: 749px) {
  .bcsaus-game-page__about {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .bcsaus-game-page__item {
    gap: 15px;
  }
}
