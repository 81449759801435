.bcsaus-casinos__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 31px;
}

.bcsaus-casinos__game {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bcsaus-casinos__game-cover {
  width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 0 28px 1px #ff00c8;
  border-radius: 24px;
}

.bcsaus-casinos__img-container {
  position: relative;
}

.bcsaus-casinos__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  border-radius: 24px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding: 15px;
  gap: 10px;
  opacity: 0;
  transition: all 0.3s;
}

.bcsaus-casinos__overlay:hover {
  opacity: 1;
}

.bcsaus-casinos__rating {
  display: flex;
  gap: 3px;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
}

.btn {
  width: 100%;
  max-width: 244px;
  height: 60px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bcsaus-casinos__play {
  background-color: #ff00ee;
  position: relative;
}

.bcsaus-casinos__play::after {
  width: 100%;
  content: "";
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  border-radius: 18px;
}

.bcsaus-casinos__play:hover::after {
  background-color: rgba(0, 0, 0, 0.5);
}

.bcsaus-casinos__more-info {
  background-color: #ffffff;
  transition: all 0.3s;
}

.bcsaus-casinos__more-info:hover {
  background-color: #ff00ee;
  color: #fff;
}

.bcsaus-game-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sugar_popup__game {
  width: 80%;
  height: 600px;
  max-width: 1240px;
  position: relative;
  padding: 30px;
}

.bcsaus-game__frame {
  width: 100%;
  height: 100%;
}

.bcsaus-popup__close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .bcsaus-casinos__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (max-width: 749px) {
  .bcsaus-casinos__overlay {
    opacity: 1;
  }
}

@media (max-width: 550px) {
  .bcsaus-casinos__grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
