@import url("/src/vendor/fonts/fonts.css");
@import url("/src/vendor/normalize.css");

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  background-color: #000100;
}

.bcsaus-page {
  max-width: 1440px;
  margin: 0 auto;
  text-align: left;
  font-family: "Roboto", "Arial", sans-serif;
}

h1,
h2 {
  font-size: 48px;
  line-height: 72px;
  margin: 0;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

p,
a,
li {
  margin: 0;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.t-32 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}

.t-24 {
  font-size: 24px;
  line-height: 36px;
}

.t-16 {
  font-size: 16px;
  line-height: 24px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: start;
}

.col-white {
  color: #fff;
}

.col-pink {
  color: #ff00ee;
}

.pad {
  max-width: 1240px;
  margin: 80px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.hidden {
  display: none;
}

.bcsaus-privacy {
  gap: 20px;
  margin: 40px auto;
}

.bcsaus-privacy h1,
.bcsaus-privacy h2 {
  text-align: start;
}

@media (max-width: 1100px) {
  .pad {
    margin: 50px auto;
    gap: 40px;
  }

  .bcsaus-privacy {
    gap: 20px;
    margin: 40px auto;
  }
}

@media (max-width: 749px) {
  h1,
  h2 {
    font-size: 38px;
    line-height: 57px;
  }

  .pad {
    margin: 30px auto;
    gap: 30px;
  }

  .bcsaus-privacy {
    gap: 20px;
  }
}
